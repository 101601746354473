<div class="dialog-header">
    <span>Edit Point of Contact</span>
    <button mat-icon-button mat-dialog-close>
        <fa-icon icon="times"></fa-icon>
    </button>
</div>
<div class="dialog-content" #content>
    <form [formGroup]="infoGroup">
        <mat-form-field class="highlight-focus raised-label" floatLabel="always">
            <mat-label>Name</mat-label>
            <input type="text" matInput [(ngModel)]="pocData.name" formControlName="name" />
            <mat-error>
                <ng-container *ngIf="infoGroup.controls.name.hasError('required')">
                    <span>A name is required</span>
                </ng-container>
                <ng-container *ngIf="infoGroup.controls.name.hasError('pattern')">
                    <span>POC name must consist of more than a space.</span>
                </ng-container>
            </mat-error>
        </mat-form-field>

        <mat-form-field class="highlight-focus raised-label" floatLabel="always">
            <mat-label>Organization</mat-label>
            <input type="text" matInput [(ngModel)]="pocData.organization" formControlName="organization" />
            <mat-error>
                <ng-container *ngIf="infoGroup.controls.organization.hasError('required')">
                    <span>An organization is required</span>
                </ng-container>
                <ng-container *ngIf="infoGroup.controls.organization.hasError('pattern')">
                    <span>POC organization must consist of more than a space.</span>
                </ng-container>
            </mat-error>
        </mat-form-field>

        <div class="field-row">
            <mat-form-field class="highlight-focus raised-label" floatLabel="always">
                <mat-label>Job Title</mat-label>
                <input type="text" matInput [(ngModel)]="pocData.title" formControlName="jobTitle" />
                <mat-error>
                    <ng-container *ngIf="infoGroup.controls.jobTitle.hasError('required')">
                        <span>A job title is required</span>
                    </ng-container>
                    <ng-container *ngIf="infoGroup.controls.jobTitle.hasError('pattern')">
                        <span>POC job title must consist of more than a space.</span>
                    </ng-container>
                </mat-error>
            </mat-form-field>

            <mat-form-field class="highlight-focus raised-label" floatLabel="always">
                <mat-label>Department</mat-label>
                <input type="text" matInput [(ngModel)]="pocData.department" formControlName="department" />
                <mat-error>
                    <ng-container *ngIf="infoGroup.controls.department.hasError('required')">
                        <span>A department is required</span>
                    </ng-container>
                    <ng-container *ngIf="infoGroup.controls.department.hasError('pattern')">
                        <span>POC department must consist of more than a space.</span>
                    </ng-container>
                </mat-error>
            </mat-form-field>
        </div>
    </form>

    <div class="section-header">CONTACT INFO</div>

    <form [formGroup]="contactGroup">
        <div class="field-row">
            <mat-form-field class="highlight-focus raised-label" floatLabel="always">
                <mat-label>Work Email</mat-label>
                <input type="text" matInput [(ngModel)]="pocData.email" formControlName="email" />
                <mat-error>
                    <span *ngIf="contactGroup.controls.email.hasError('required')"> An email is required </span>
                    <span *ngIf="contactGroup.controls.email.hasError('email')">
                        Email is not in proper format (ex your.name&#64;example.com)
                    </span>
                    <span *ngIf="contactGroup.controls.email.hasError('unique')">
                        {{ contactGroup.controls.email.errors['unique'] }}
                    </span>

                    <ng-container *ngIf="contactGroup.controls.email.hasError('pattern')">
                        <span>Email must consist of more than a space.</span>
                    </ng-container>
                </mat-error>
            </mat-form-field>

            <mat-form-field class="highlight-focus raised-label" floatLabel="always">
                <mat-label>Work Phone Number</mat-label>
                <input
                    type="text"
                    matInput
                    formControlName="phoneNumber"
                    (keypress)="checkNumberInput($event)"
                    [ngModel]="pocData.telephone | phoneNumber"
                    (ngModelChange)="transformPhone($event, true)" />

                <mat-error>
                    <span *ngIf="contactGroup.controls.phoneNumber.hasError('required')"> Phone number is required </span>

                    <span *ngIf="contactGroup.controls.phoneNumber.hasError('length')">
                        {{ contactGroup.controls.phoneNumber.errors['length'] }}
                    </span>

                    <ng-container *ngIf="contactGroup.controls.phoneNumber.hasError('pattern')">
                        <span>A phone number must consist of more than a space.</span>
                    </ng-container>
                </mat-error>
            </mat-form-field>
        </div>
    </form>
</div>

<div class="dialog-actions">
    <button mat-stroked-button mat-dialog-close>Close</button>
    <button mat-flat-button color="primary" (click)="save()" [disabled]="!isFormValid()">Save</button>
</div>
