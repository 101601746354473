<div class="filters">
    <mat-form-field class="filter-select" appearance="outline">
        <mat-label>Filter by Report</mat-label>
        <mat-select [value]="selectedType" (selectionChange)="changeFilterType($event)" placeholder="Filter by Report">
            <mat-option [value]="'all'">All</mat-option>
            <mat-option *ngFor="let type of selectableFilters" [value]="type">{{ type }}</mat-option>
        </mat-select>
    </mat-form-field>
</div>

<mat-card>
    <mat-card-title>
        <h4>Current Report Subscriptions</h4>

        <div class="actions">
            <button mat-stroked-button *ngIf="!isGroupEditing" (click)="toggleGroupEdit()" [disabled]="!hasSelected()">
                <fa-icon inline icon="edit"></fa-icon>
            </button>

            <button mat-stroked-button *ngIf="!isGroupEditing" (click)="groupDelete()" [disabled]="!hasSelected()">
                <fa-icon inline icon="trash-alt"></fa-icon>
            </button>

            <button mat-stroked-button *ngIf="isGroupEditing" (click)="toggleGroupEdit()" [disabled]="!hasSelected()">
                <fa-icon inline icon="save"></fa-icon>
            </button>

            <button mat-stroked-button *ngIf="isGroupEditing" (click)="groupCancel()" [disabled]="!hasSelected()">
                <fa-icon inline icon="times"></fa-icon>
            </button>
        </div>
    </mat-card-title>

    <mat-card-content>
        <div class="table-container">
            <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
                <ng-container matColumnDef="check">
                    <th mat-header-cell *matHeaderCellDef class="icon-col">
                        <mat-checkbox
                            class="header-checkbox"
                            [checked]="allChecked()"
                            [indeterminate]="isIndeterminate()"
                            (change)="toggleAll($event.checked)"></mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let info" class="icon-col">
                        <div>
                            <mat-checkbox
                                [checked]="info.isChecked"
                                (change)="toggleSelection(info, $event.checked)"
                                (click)="$event.stopPropagation()"></mat-checkbox>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>Name</th>
                    <td
                        mat-cell
                        *matCellDef="let info"
                        class="ellipsis"
                        [matTooltip]="info.name"
                        matTooltipPosition="above"
                        matTooltipShowDelay="250">
                        {{ info.name }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="subscription">
                    <th mat-header-cell *matHeaderCellDef class="med-col">Report</th>
                    <td mat-cell *matCellDef="let info" class="med-col">
                        <div>
                            {{ info.userDefinedReportName }}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef class="med-col">Email</th>
                    <td mat-cell *matCellDef="let info" class="med-col"></td>
                </ng-container>

                <ng-container matColumnDef="start">
                    <th mat-header-cell *matHeaderCellDef>Start Date</th>
                    <td mat-cell *matCellDef="let info">
                        <div *ngIf="!info.isEditing; else edit">
                            {{ info.startDate | momentDate : 'M/D/YYYY' }}
                        </div>
                        <ng-template #edit>
                            <eaglei-date-range
                                [newDesign]="true"
                                [singlePicker]="true"
                                [startPlaceholder]="''"
                                [start]="info.startDate"
                                [min]="startMin"
                                [max]="startMax"
                                [allowFutureEndDate]="true"
                                (change)="updateDate($event, info, 'start')"></eaglei-date-range>
                        </ng-template>
                    </td>
                </ng-container>

                <ng-container matColumnDef="end">
                    <th mat-header-cell *matHeaderCellDef>End Date</th>
                    <td mat-cell *matCellDef="let info">
                        <div *ngIf="!info.isEditing; else edit">
                            {{ info.endDate | momentDate : 'M/D/YYYY' }}
                        </div>
                        <ng-template #edit>
                            <eaglei-date-range
                                [newDesign]="true"
                                [singlePicker]="true"
                                [startPlaceholder]="''"
                                [start]="info.endDate"
                                [min]="info.startDate"
                                [max]="endMax"
                                [allowFutureEndDate]="true"
                                (change)="updateDate($event, info, 'end')"></eaglei-date-range>
                        </ng-template>
                    </td>
                </ng-container>

                <ng-container matColumnDef="frequency">
                    <th mat-header-cell *matHeaderCellDef>Frequency Per Day</th>
                    <td mat-cell *matCellDef="let info">
                        <div *ngIf="!info.isEditing">
                            {{ info.frequency }}
                        </div>
                        <div *ngIf="info.isEditing">
                            <mat-form-field appearance="outline" (click)="$event.stopPropagation()">
                                <mat-select [(ngModel)]="info.frequency">
                                    <mat-option [value]="1">1</mat-option>
                                    <mat-option [value]="2">2</mat-option>
                                    <mat-option [value]="3">3</mat-option>
                                    <mat-option [value]="4">4</mat-option>
                                    <mat-option [value]="6">6</mat-option>
                                    <mat-option [value]="8">8</mat-option>
                                    <mat-option [value]="12">12</mat-option>
                                    <mat-option [value]="24">24</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let info">
                        <div *ngIf="!isGroupEditing && !info.isChecked" class="actions">
                            <fa-icon *ngIf="!info.isEditing" icon="edit" class="icon" (click)="toggleEdit($event, info)"></fa-icon>
                            <fa-icon *ngIf="!info.isEditing" icon="trash-alt" class="icon" (click)="unsub($event, info)"></fa-icon>

                            <fa-icon *ngIf="info.isEditing" icon="save" class="icon" (click)="toggleEdit($event, info)"></fa-icon>
                            <fa-icon *ngIf="info.isEditing" icon="times" class="icon" (click)="cancelEdit($event, info)"></fa-icon>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="expand">
                    <th mat-header-cell *matHeaderCellDef class="ex-small-col"></th>
                    <td mat-cell *matCellDef="let info" class="ex-small-col">
                        <div>
                            <fa-icon [icon]="info.expanded ? 'chevron-up' : 'chevron-down'"> </fa-icon>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="empty">
                    <td mat-cell *matCellDef="let info"></td>
                </ng-container>

                <ng-container matColumnDef="email-list">
                    <td mat-cell *matCellDef="let info" [attr.colspan]="columnNames.length - 2">
                        <div class="hide-expanded-row" [class.show-expanded-row]="info.expanded">
                            <div *ngFor="let email of info.subscribers; let i = index; trackBy: trackIndex" class="actions">
                                <div *ngIf="!info.isEditing; else editor">
                                    {{ email }}
                                </div>
                                <ng-template #editor>
                                    <mat-form-field appearance="outline" class="filter-select">
                                        <input
                                            matInput
                                            [ngModel]="info.subscribers[i]"
                                            (ngModelChange)="info.subscribers[i] = $event"
                                            [formControl]="getFormControl(info, i)" />
                                        <mat-error>
                                            <span *ngIf="hasError(info, i, 'email')">Email is not in proper format</span>
                                            <span *ngIf="hasError(info, i, 'pattern')">Email must consist of more than a space.</span>
                                        </mat-error>
                                    </mat-form-field>

                                    <div *ngIf="canDeleteSubscribers(info)">
                                        <fa-icon icon="trash-alt" class="icon clickable" (click)="removeEmail(info, email)"></fa-icon>
                                    </div>
                                </ng-template>
                            </div>

                            <div *ngIf="info.isEditing" class="clickable" (click)="addEmail(info)">
                                <fa-icon icon="plus" class="icon" style="margin-right: 5px"></fa-icon>
                                <span>Add another Email Address</span>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnNames"></tr>

                <tr
                    mat-row
                    *matRowDef="let row; columns: columnNames"
                    class="clickable hoverable"
                    [class.selected]="row.expanded"
                    (click)="toggleExpansion(row)"></tr>
                <tr mat-row *matRowDef="let row; columns: ['empty', 'empty', 'empty', 'email-list']" class="expandable-row"></tr>
            </table>

            <mat-paginator [pageSizeOptions]="[25, 50, 75, 100]" showFirstLastButtons></mat-paginator>
        </div>
    </mat-card-content>
</mat-card>
